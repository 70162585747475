@use 'sass:color';
@use "variables";

body {    
    color: rgba(255,255,255,.75);
    background-color: variables.$sidebar-bg-color;
    background-image: linear-gradient(
	to bottom,
	color.adjust(variables.$sidebar-bg-color, $lightness:- 100%),
	color.adjust(variables.$sidebar-totob-color, $lightness: -5%),
      	color.adjust(variables.$sidebar-totor-color, $lightness: 10%)),url(../images/sdss_j1038_h.png),url(../images/gradmag_h.png),url(../images/background.png); 
    background-blend-mode: lighten;
    background-repeat: repeat-y, no-repeat, repeat-x, repeat;
    background-position: center, left top, left top, center;
    background-size: auto, auto 1.4*variables.$margin-top, auto variables.$margin-top, auto;    
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 100vw;
    min-height: 100vh;
}



#sidebar {
    flex: 0 0 auto;
    padding: variables.$sidebar-padding 3*variables.$sidebar-padding;
    
    .site-title {
	font-weight: normal;
	font-size: variables.$large-title-size;
	color: black;
	text-shadow: 0px 0px 5px white, 0px 0px 20px white, 0px 0px 30px white, 0px 0px 40px white, 0px 0px 50px white ;
	margin-top: 0;
	margin-bottom: variables.$heading-spacing;
	a {
	    color: inherit;
	    &:hover {
		color: white;
		text-shadow: 0px 0px 5px black, 0px 0px 20px black,
		0px 0px 30px black, 0px 0px 40px black, 0px 0px 50px black ;
	    }
	}

	.back-arrow { margin-right: 0.0rem; }
    }
}


// Container is flexbox as well -- we want content div to stretch and fill


.container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    max-height: variables.$maximum-height;
    border-top-left-radius: variables.$border-corner variables.$border-corner;
    > .content {
	flex-grow: 1;
    }    
}

.content {    
    background: variables.$body-bg;
    color: variables.$body-color;
    padding: variables.$section-spacing;
    border-top-left-radius: variables.$border-corner variables.$border-corner;
    border-top-right-radius: variables.$border-corner variables.$border-corner;
}

.banner {
    color: variables.$red ;		  
    font-family: Helvetica, sans-serif;
//    font-variant: small-caps;
    font-weight: normal;
    font-size: variables.$large-title-size;
    text-align: center;
    text-shadow: 2px 2px 1px grey;
    margin-bottom: variables.$margin-bottom;
}

.diapositive {
    width: variables.$diapositive-size;
    height: variables.$diapositive-size;
    border: 1px solid grey;
    border-radius: 10px;
    background-color: white;
    box-shadow: variables.$diapositive-shadow;
    margin-top: 25px;
    margin-bottom: 25px;
}

.diapositive img {
    border: 1px solid grey;
    border-radius: 0px;
    background: black;
}


.polaroid {
    display: block;
    max-width: 0.6*variables.$maximum-width;
    border: 1px solid grey;
    background-color: white;
    box-shadow: variables.$diapositive-shadow;
    text-align: center;
    font-size: 0.8rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 25px;
}

.polaroid img {
    max-width:100%;
    background: white;
}


.bfimage {
    width: variables.$maximum_width;
    height: variables.$maximum_height;
    border: 0;
    margin: 0;
}

.bfimage img {
    max-width:100%;
    background: black;
}

.publication {
}


.publication table {
    max-width: 0.4*variables.$maximum-width;
    padding: 0;
    margin: 0;
    border: 0;
    text-align: left;
    table-layout: fixed;
}

.publication th {
    width: 0.07*variables.$maximum-width;
}


/* -----------------------------------------------------------
  Mobile view
----------------------------------------------------------- */



// Hide secondary nav content in sidebar
// Hide lead paragraph in sidebar
#sidebar {
    header ~ *, header ~ nav, p.lead {
	display: none;
    }
}

// Make header elements blend into sidebar / background

.container > header {
    color: white;
    margin: (variables.$heading-spacing - variables.$section-spacing)
		variables.$section-spacing
		variables.$section-spacing;
    h1, h2 {
	color: white;

	&:last-child {
	    margin-bottom: 0;
	}
    }
}

.banner {
    display: none;
}


/* -----------------------------------------------------------
  Mobile view for home page)
----------------------------------------------------------- */

.index #sidebar {

    // Center sidebar content
    text-align: center;

    // Bigger title
    .site-title {
	font-size: variables.$large-title-size
    }

    // Show secondary nav content + lead
    header ~ *, p.lead {
	display: block;
    }

    header {
	~ nav {
	    display: flex;
	}
    }

    // Slightly more bottom padding to compensate for heading not match 100% of
    // line-height on top
    > *:last-child {
	margin-bottom: 0.0rem;
    }
}



/* -----------------------------------------------------------
  Tablet / Desktop view
----------------------------------------------------------- */

@media (min-width: variables.$large-breakpoint) {
    body {
	flex-direction: row;
	height: 100vh;
	overflow: auto;
	> * { max-height: 100vh; overflow: auto; }
    }

    /* Undo mobile CSS */

    
    #sidebar, .index #sidebar {
	width: variables.$sidebar-width;
	text-align: left;

	> *:last-child {
	    margin-bottom: 0;
	}
    }

    #sidebar {
	.site-title {
	    font-size: variables.$large-title-size;

	    a { color: inherit; }
	    .back-arrow { display: none; }
	}

	p.lead, header ~ * {
	    display: block;
	}

	header ~ nav {
	    display: flex;
	}
    }

    .index #sidebar { margin-bottom: 0; }

    // Make entire container background white to contrast against sidebar
    .container {
	background: variables.$body-bg;
	color: variables.$body-color;
	padding: variables.$section-spacing variables.$section-spacing * 2;
	margin-top: variables.$margin-top;
	height: 100vh;

	> header {
	    color: inherit;
	    margin: 0;
	    h1, h2 {
		color: inherit;

		&:last-child {
		    margin-bottom: variables.$heading-spacing;
		}
	    }
	}
	
	> * {
	    max-width: variables.$maximum-width;
	    padding: 0;
	}
    }
    .banner {
	display: inline;
    }

}



/* -----------------------------------------------------------
  Sidebar links + nav
----------------------------------------------------------- */

#sidebar a {
    color: #fff;

    svg {
	fill: rgba(255, 255, 255, 0.85);
    }

    &:hover, &:focus, &.active {
	svg { fill: #fff; }
    }

    &:hover, &:focus {
		 text-decoration: underline;
		 &.icon {
		     text-decoration: none;
		 }
	     }

    &.active {
	font-weight: bold;
    }
}

#sidebar nav {
    display: flex;
}

#sidebar-nav-links {
    flex-flow: column nowrap;
}

#sidebar-icon-links {
    max-width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 1rem;

    @media (min-width: variables.$large-breakpoint) {
	margin-left: -0.25em;
	justify-content: flex-start;
    }
}

#sidebar nav > * {
    display: block;
    line-height: 1.75;
}

#sidebar nav > .icon {
    font-size: 1.5rem;
    display: inline-block;
    margin: 0 0.25em;
}


/* -----------------------------------------------------------
  Sticky sidebar

  Set $sidebar-stick variable to affix sidebar contents to the
  bottom of the sidebar in tablets and up.
----------------------------------------------------------- */

@if variables.$sidebar-sticky {
    @media (min-width: variables.$large-breakpoint) {
	body {
	    align-items: flex-end;
	}
    }
}


/* -----------------------------------------------------------
  Reverse layout

  Flip the orientation of the page by placing the `#sidebar`
  on the right.
----------------------------------------------------------- */

@if variables.$layout-reverse {
    @media (min-width: variables.$large-breakpoint) {
	.container {
	    order: -1;
	}
    }
}
