@use "variables";
@use "layout";

.member-title {
    margin-top: 0;
}


.members-type {
    padding-top: variables.$section-spacing;
    border-bottom: 1px solid variables.$border-color;
}
	     

.members-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: variables.$maximum-width;
    list-style: disc outside;
}

.members-list p {
    margin-left:0;
    margin-right:0;
    text-align:left;
    max-width: 0.45*variables.$maximum-width;
}  


.members-list > * {
    flex-shrink: 1;
    flex-grow: 1;
    width: 0.5*variables.$maximum-width;
}

.members-portrait {
    @extend .diapositive;
    padding: variables.$film-padding-portrait;
}

.members-portrait img {    
    width: variables.$film-short-size;
    height: variables.$film-long-size;
}


.members-landscape {
    @extend .diapositive;
    padding: variables.$film-padding-landscape;   
}

.members-landscape img {  
    width: variables.$film-long-size;
    height: variables.$film-short-size;
}


.members-info {
   
}


.members-info table, th, td {
    margin-top: 0;
    border: 0;
    text-align: left;
}


