@use "variables";

/*
  Pagination

  Super lightweight (HTML-wise) blog pagination. Should only be visible
  when there is navigation available -- single buttons at top or bottom
  of each page.
*/

.pagination {
  margin-bottom: variables.$section-spacing;
  color: variables.$gray-3;
  text-align: center;

  > a {
    display: inline-block;
    padding: variables.$padding-v variables.$padding-h;
    border: solid variables.$border-color;
    border-radius: variables.$border-radius;
    border-width: 1px;
    width: 60%;
    max-width: variables.$sidebar-width;
    background: variables.$body-bg;
    box-shadow: variables.$default-box-shadow;

    &:hover {
      background-color: variables.$border-color;
    }
  }
}

.pagination .pager{
    > ul {
	text-align: center;
	list-style: none;
    }    

    > li {
	display: inline;
	text-align: center;
	border: solid variables.$border-color;
	border-radius: 0;
	padding: variables.$pager-spacing;
	margin: 0.5*variables.$pager-spacing;
	&:hover {
	    background-color: variables.$border-color;
	}
    }
    .selected {
	background-color: variables.$gray-3;
    }
}


// Bottom -> margin-top;
* + .pagination {
  margin-top: variables.$section-spacing;
}

// Push above header if newer on mobile
.content .pagination:first-child {
  margin-top: -(variables.$section-spacing) * 2;
}

// Make room for larger header by extending margin below title
.index #sidebar {
  padding-bottom: calc(#{variables.$section-spacing} + #{variables.$padding-v});
}

// But not on page1
//.home.index #sidebar {
//  padding-bottom: $section-spacing;
//}

// Undo for larger screens
@media (min-width: variables.$large-breakpoint) {
  .pagination > a {
    box-shadow: none;

    &:hover { box-shadow: variables.$default-box-shadow; }
  }

  .content .pagination:first-child {
    margin-top: 0;

    + * {
      padding-top: variables.$section-spacing;
      border-top: 1px solid variables.$border-color;
      margin-top: variables.$section-spacing;
    }
  }

  .index #sidebar {
    padding-bottom: variables.$section-spacing;
  }
}
